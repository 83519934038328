<template>
  <component
    :is="tagToUse"
    class="base-container"
    :class="{
      'base-container--without-padding': props.withoutPadding,
      'base-container--fluid': props.fluid,
    }"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
  withoutPadding: {
    type: Boolean,
  },
  fluid: {
    type: Boolean,
  },
});

const tagToUse = computed(() => {
  return props.tag || 'div';
});
</script>

<style lang="scss">
.base-container {
  padding: 0 10px;
  transition: $primary-transition;

  &.base-container--without-padding {
    padding: 0;

    @media (min-width: $tablet) {
      padding: 0;
    }
  }

  &.base-container--fluid {
    max-width: 100%;

    @media (min-width: $desktop) {
      max-width: 100%;
    }
  }

  @media (min-width: $tablet) {
    padding: 0 20px;
  }

  @media (min-width: $desktop) {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: $desktop-l) {
    padding: 0;
  }
}
</style>
